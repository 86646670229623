import { isEqual, includes, find, filter, sortBy } from "lodash-es";
import axios from "axios";
const inflight = {};

export default {
  getCharacters(fandomKey) {
    const chars = this.characters[fandomKey];
    if (chars !== undefined) {
      return sortBy(chars);
    }

    if (inflight[fandomKey]) {
      return;
    }

    inflight[fandomKey] = true;

    axios
      .get(
        `https://tagset2023-default-rtdb.firebaseio.com/characters/${fandomKey}.json`
      )
      .then(res => {
        this.$store.commit("addChar", {
          key: fandomKey,
          result: sortBy(res.data)
        });
        return sortBy(res.data);
      });
  },
  getPrompts(fandomKey) {
    const newVal = this.prompts;
    newVal[fandomKey] = "loading";
    this.$store.commit("setPrompts", {});
    this.$store.commit("setPrompts", newVal);

    axios
      .get(
        `https://tagset2023-default-rtdb.firebaseio.com/prompts/${fandomKey}.json`
      )
      .then(res => {
        if (res.data && res.data.length) {
          let results = sortBy(res.data, o => o.username.toLowerCase());
          newVal[fandomKey] = results;
        } else {
          newVal[fandomKey] = [];
        }

        this.$store.commit("setPrompts", {});
        this.$store.commit("setPrompts", newVal);
      });
  },
  highlightChars(letter, key) {
    if (
      this.letterChars &&
      this.letterChars.fandom === key &&
      isEqual(this.letterChars.characters, letter.characters)
    ) {
      this.letterChars = null;
      return;
    }
    this.letterChars = {
      fandom: key,
      characters: letter.characters
    };
  },
  letterHasChar(key, char) {
    return (
      this.letterChars.fandom === key &&
      includes(this.letterChars.characters, char)
    );
  },

  toggle(fandom) {
    if (find(this.$store.state.bookmarks, f => f[".key"] == fandom[".key"])) {
      this.remove(fandom);
      return false;
    }
    const newVal = this.bookmarks;
    newVal.push(fandom);
    this.$store.commit("setBookmarks", newVal);
    this.$localStorage.set(
      `${process.env.VUE_APP_COLLECTION_ID}bookmarks`,
      JSON.stringify(this.bookmarks)
    );
  },
  toggleLettermark(letter, fandom) {
    if (
      find(this.$store.state.lettermarks, o => {
        return o.username === letter.username && o.key === fandom[".key"];
      })
    ) {
      this.removeLettermark(letter, fandom[".key"]);
      return false;
    }

    const newVal = this.$store.state.lettermarks || [];

    newVal.push({
      ...letter,
      name: fandom.name,
      key: fandom[".key"]
    });

    this.$store.commit("setLettermarks", newVal);
    this.$localStorage.set(
      `${process.env.VUE_APP_COLLECTION_ID}lettermarks`,
      JSON.stringify(newVal)
    );
  },
  togglePromptmark(prompt) {
    if (
      find(this.$store.state.promptmarks, o => {
        return o.username === prompt.username && o.fandom === prompt.fandom;
      })
    ) {
      this.removePromptmark(prompt);
      return false;
    }

    const newVal = this.$store.state.promptmarks;
    newVal.push(prompt);

    this.$store.commit("setPromptmarks", newVal);

    this.$localStorage.set(
      `${process.env.VUE_APP_COLLECTION_ID}promptmarks`,
      JSON.stringify(this.$store.state.promptmarks)
    );
  },
  remove(fandom) {
    this.$store.commit(
      "setBookmarks",
      filter(this.$store.state.bookmarks, o => {
        return o.name !== fandom.name;
      })
    );
    this.$localStorage.set(
      `${process.env.VUE_APP_COLLECTION_ID}bookmarks`,
      JSON.stringify(this.$store.state.bookmarks)
    );
  },
  removeLettermark(letter, key) {
    if (!key && letter.key !== undefined) {
      key = letter.key;
    }

    this.$store.commit(
      "setLettermarks",
      filter(this.$store.state.lettermarks, o => {
        return !(o.username === letter.username && o.key === key);
      })
    );
    this.$localStorage.set(
      `${process.env.VUE_APP_COLLECTION_ID}lettermarks`,
      JSON.stringify(this.lettermarks)
    );
  },
  removePromptmark(prompt) {
    this.$store.commit(
      "setPromptmarks",
      filter(this.$store.state.promptmarks, o => {
        return (
          o.fandom !== prompt.fandom ||
          (o.username !== prompt.username && o.fandom === prompt.fandom)
        );
      })
    );

    this.$localStorage.set(
      `${process.env.VUE_APP_COLLECTION_ID}promptmarks`,
      JSON.stringify(this.$store.state.promptmarks)
    );
  },
  hasBookmark(fandom) {
    return find(this.bookmarks, o => {
      return o.name === fandom.name;
    });
  },
  hasLettermark(letter, fandom) {
    return find(this.$store.state.lettermarks, o => {
      return o.username === letter.username && o.key === fandom[".key"];
    });
  },
  hasPromptmark(prompt) {
    return find(this.$store.state.promptmarks, o => {
      return o.username === prompt.username && o.fandom === prompt.fandom;
    });
  },

  formatUrl(url) {
    if (!this.$store.state.options.destyle || !url) {
      return url;
    }

    url = url.trim();

    const isDW = url.indexOf("dreamwidth.org") > -1;
    const isLJ = url.indexOf("livejournal.com") > -1;
    const isTumblr = url.indexOf("tumblr.com") > -1;
    const isDocs = url.indexOf("docs.google") > -1;

    if (isDW) {
      if (url.indexOf("?style=") === -1 && url.indexOf("&style=") === -1) {
        if (url.indexOf("?") > -1) {
          return `${url}&style=site`;
        }

        return `${url}?style=site`;
      }
    }

    if (isLJ) {
      if (
        url.indexOf("?style=") === -1 &&
        url.indexOf("&style=") === -1 &&
        url.indexOf("format=") === -1
      ) {
        if (url.indexOf("?") > -1) {
          return `${url}&format=light`;
        }

        return `${url}?format=light`;
      }
    }

    if (isTumblr) {
      if (url.indexOf("/mobile") === -1) {
        return `${url}/mobile`;
      }
    }

    if (isDocs) {
      if (url.indexOf("/mobilebasic") === -1) {
        return `${url.replace(/\/edit.*$/, "")}/mobilebasic`;
      }
    }

    return url;
  }
};
