import { render, staticRenderFns } from "./promptTable.vue?vue&type=template&id=5546849d&scoped=true&"
import script from "./promptTable.vue?vue&type=script&lang=js&"
export * from "./promptTable.vue?vue&type=script&lang=js&"
import style0 from "./promptTable.vue?vue&type=style&index=0&id=5546849d&lang=scss&scoped=true&"
import style1 from "./promptTable.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5546849d",
  null
  
)

export default component.exports