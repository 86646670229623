<template>
  <div class="bookmarks">
    <h2>Bookmarks</h2>

    <p>
      You can bookmark fandoms, letters, and - when they become available -
      individual prompts by clicking on the
      <span class="far fa-heart"></span> icon. This data is saved in your
      browser, and will remain for as long as you do not clear the cache.
    </p>
    <button
      @click="showbackup = !showbackup"
      class="my-1 c-hand btn btn-link d-block"
    >
      <strong>
        <span
          :class="['fas', showbackup ? 'fa-angle-down' : 'fa-angle-right']"
        ></span>
        {{ showbackup ? "Hide" : "Show" }} Backup Instructions</strong
      >
    </button>
    <div v-if="showbackup">
      <p>
        To transfer your bookmarks between browsers/computers or just to back
        them up, copy
        <em>exactly</em>
        the text in the "Copy" field. In the browser you want to load your
        bookmarks in, paste it into the "Load" field, then hit Load. You
        <strong>do not</strong> have to do this if you just use the same browser
        every time.
      </p>

      <div class="columns">
        <div class="col-6">
          <strong>Copy:</strong>
          <textarea cols="10" rows="2" v-model="marks"></textarea>
        </div>
        <div class="col-6">
          <strong>Load:</strong>
          <textarea cols="10" rows="2" v-model="saved"></textarea>
        </div>
      </div>
      <div class="my-2">
        <button class="btn" @click="loadSaved">Load!</button>
      </div>
      <hr />
    </div>

    <label class="form-checkbox form-inline">
      <input type="checkbox" v-model="hideCharacters" />
      <i class="form-icon"></i> Hide Nominated Characters column
    </label>

    <div class="letters">
      <h3>
        Letters
        <span
          :class="[
            'fas',
            showLettersSection ? 'fa-angle-down' : 'fa-angle-right',
          ]"
          @click="showLettersSection = !showLettersSection"
        ></span>
      </h3>

      <template v-if="showLettersSection">
        <ul v-if="showLettersSection && lettermarks && lettermarks.length">
          <li v-for="letter in lettermarks" :key="letter.url">
            <template v-if="letter.isPinchhitter">(</template>
            <a :href="formatUrl(letter.url)" target="_blank">
              {{ letter.username }}
            </a>
            ({{ letter.name }})
            <template v-if="letter.isPinchhitter">)</template>
            <span
              @click="removeLettermark(letter)"
              class="remove far fa-times-circle"
            ></span>
          </li>
        </ul>
        <span v-else>You haven't bookmarked any letters yet ):</span>
      </template>
    </div>

    <div class="divider"></div>

    <div class="fandoms">
      <h3>
        Fandoms
        <span
          :class="[
            'fas',
            showFandomsSection ? 'fa-angle-down' : 'fa-angle-right',
          ]"
          @click="showFandomsSection = !showFandomsSection"
        ></span>
      </h3>

      <label class="form-checkbox form-inline" v-if="hasLetters">
        <input type="checkbox" v-model="hideLetters" />
        <i class="form-icon"></i> Hide Letters column
      </label>
      <template v-if="showFandomsSection">
        <table v-if="bookmarks && bookmarks.length">
          <thead>
            <tr>
              <th class="fandom">Fandom</th>
              <th class="characters" v-if="!hideCharacters">
                Nominated Characters
              </th>
              <th class="letters" v-if="!hideLetters">
                Letters
                <span
                  class="tooltip c-hand text-normal"
                  data-tooltip="Letters submitted to the letters
post before the release of all prompts.
See the help page for more information."
                >
                  <sup>
                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                  </sup>
                </span>
              </th>
              <th class="prompts" v-if="unlock">Prompts</th>
            </tr>
          </thead>
          <draggable
            v-model="bookmarks"
            is="draggable"
            tag="tbody"
            handle=".move"
          >
            <tr
              v-for="(fandom, index) in bookmarks"
              :class="{ odd: index % 2 !== 0 }"
              :key="fandom.name"
            >
              <td class="fandom">
                <span class="fas fa-arrows-alt-v move"></span>
                <router-link :to="`/fandom/${fandom['.key']}`">
                  {{ fandom.name }} </router-link
                >&nbsp;
                <span
                  @click="remove(fandom)"
                  class="remove far fa-times-circle"
                ></span>
                &nbsp;
                <div class="meta">
                  <span class="category meta-tag" v-if="!options.hideCategory">
                    {{ fandom.category ? fandom.category.join(", ") : "" }}
                  </span>
                </div>
              </td>

              <td class="characters" v-if="!hideCharacters">
                <ul>
                  <li
                    v-for="char in getCharacters(fandom['.key'])"
                    :key="char"
                    :class="{
                      highlight:
                        letterChars && letterHasChar(fandom['.key'], char),
                    }"
                  >
                    {{ char }}
                  </li>
                </ul>
              </td>
              <td class="letters" v-if="!hideLetters">
                <ul v-if="letters">
                  <li
                    v-for="letter in letters[fandom['.key']]"
                    :key="letter.username"
                    class="letter"
                  >
                    <a
                      class="user"
                      :href="formatUrl(letter.url)"
                      target="_blank"
                    >
                      {{ letter.username }} </a
                    >&nbsp;
                    <button
                      class="bookmark-letter"
                      @click="toggleLettermark(letter, fandom)"
                    >
                      <span
                        v-if="lettermarks && hasLettermark(letter, fandom)"
                        class="fas fa-heart"
                      ></span>
                      <span v-else class="far fa-heart"></span>
                    </button>
                    <div class="meta">
                      <button
                        class="char-count meta-tag"
                        @click="highlightChars(letter, fandom['.key'])"
                      >
                        Chars:
                        {{
                          letter.characters === undefined
                            ? "Any"
                            : letter.characters.length
                        }}
                      </button>
                    </div>
                  </li>
                </ul>
              </td>
              <td v-if="unlock" class="prompts">
                <button
                  class="btn btn-primary"
                  v-if="!prompts[fandom['.key']] && hasPrompts[fandom['.key']]"
                  @click="getPrompts(fandom['.key'])"
                >
                  Get Prompts
                </button>
                <div v-if="prompts[fandom['.key']] === 'loading'">
                  Loading...
                </div>
                <template
                  v-if="
                    prompts[fandom['.key']] &&
                    prompts[fandom['.key']].length &&
                    prompts[fandom['.key']] !== 'loading'
                  "
                >
                  <Prompt-Table
                    :prompts="prompts[fandom['.key']]"
                  ></Prompt-Table>
                </template>
                <span v-if="!hasPrompts[fandom['.key']]">No prompts ):</span>
              </td>
            </tr>
          </draggable>
        </table>

        <span v-else>You haven't bookmarked any fandoms yet ):</span>
      </template>
    </div>

    <template v-if="unlock">
      <div class="divider"></div>

      <h3>
        Prompts
        <span
          :class="[
            'fas',
            showPromptsSection ? 'fa-angle-down' : 'fa-angle-right',
          ]"
          @click="showPromptsSection = !showPromptsSection"
        ></span>
      </h3>

      <template v-if="showPromptsSection">
        <div v-if="promptmarks && promptmarks.length">
          <table class="prompts">
            <thead>
              <tr>
                <th class="fave">
                  <span class="fas fa-heart"></span>
                </th>
                <th class="fandom">Username/Fandom</th>
                <th class="characters">Characters</th>
                <th class="prompts">Prompts</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(prompt, index) in promptmarks"
                :key="index"
                :class="{ odd: index % 2 !== 0 }"
              >
                <td class="fave">
                  <button class="bookmark" @click="togglePromptmark(prompt)">
                    <span
                      v-if="hasPromptmark(prompt)"
                      class="fas fa-heart"
                    ></span>
                    <span v-else class="far fa-heart"></span>
                  </button>
                </td>
                <td>
                  <a
                    href="#"
                    @click.prevent="
                      $router.push({ path: `/user/${prompt.username}` })
                    "
                    >{{ prompt.username }}</a
                  >
                  <div class="divider"></div>

                  <strong>{{ prompt.fandom }}</strong>
                </td>
                <td>
                  <ul v-if="prompt.characters" class="characters">
                    <li v-for="c in prompt.characters" :key="c">{{ c }}</li>
                  </ul>
                </td>
                <td class="prompt">
                  <div v-html="prompt.prompt"></div>
                  <a
                    v-if="prompt.letter"
                    :href="formatUrl(prompt.letter)"
                    target="blank"
                    >Letter</a
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <span v-else>You haven't bookmarked any prompts yet ):</span>
      </template>
    </template>
  </div>
</template>

<script>
import PromptTable from "../components/promptTable";
import { filter } from "lodash-es";
import { mapGetters } from "vuex";
import utils from "../components/utils.js";
import draggable from "vuedraggable";

export default {
  components: {
    PromptTable,
    draggable,
  },
  watch: {
    letters: {
      deep: true,
      handler(val) {
        if (
          (val && !Object.keys(val).length) ||
          !this.lettermarks ||
          !this.lettermarks.length
        ) {
          return;
        }
        const scrubbed = this.scrubLettermarks(this.lettermarks);
        this.$store.commit("setLettermarks", scrubbed);
        this.$localStorage.set("lettermarks", JSON.stringify(scrubbed));
      },
    },
    isModIn(val) {
      if (!val) {
        this.hideLetters = false;
      }
    },
  },
  computed: {
    ...mapGetters([
      "options",
      "hasPrompts",
      "fandoms",
      "letters",
      "characters",
      "lettermarks",
      "promptmarks",
      "prompts",
      "hasLetters",
      "unlock",
      "isModIn",
    ]),
    bookmarks: {
      get() {
        return this.$store.state.bookmarks;
      },
      set(value) {
        if (!value) {
          return;
        }
        this.$store.commit("setBookmarks", value);
        this.$localStorage.set("bookmarks", JSON.stringify(value));
      },
    },
    marks() {
      const marks = {
        fandoms: this.bookmarks,
        letters: this.lettermarks,
        prompts: this.promptmarks,
      };

      return JSON.stringify(marks);
    },
  },
  data() {
    return {
      showbackup: false,
      hideCharacters: true,
      hideLetters: false,
      saved: null,
      showLoad: false,
      letterChars: [],
      showLettersSection: true,
      showFandomsSection: true,
      showPromptsSection: true,
    };
  },
  methods: {
    ...utils,
    // Scrub out letters that have since dropped the fandom
    scrubLettermarks(lettermarks) {
      return filter(lettermarks, (l) => {
        const fandom = this.letters[l.key];
        return l && fandom && fandom[l.username];
      });
    },
    loadSaved() {
      if (
        !confirm(
          "Are you sure you want to load bookmarks? Your current bookmarks will be wiped!"
        )
      ) {
        return;
      }

      if (!this.saved || !this.saved.length) {
        alert("Your bookmarks were invalid!");
      }

      let parsed;

      try {
        parsed = JSON.parse(this.saved);
      } catch (e) {
        alert("Your bookmarks were invalid!");
        return;
      }

      if (!this.saved || !this.saved.length) {
        return;
      }
      this.$localStorage.set("bookmarks", JSON.stringify(parsed.fandoms || []));
      this.$localStorage.set(
        "lettermarks",
        JSON.stringify(parsed.letters || [])
      );
      this.$localStorage.set(
        "promptmarks",
        JSON.stringify(parsed.prompts || [])
      );
      this.$store.commit("setLettermarks", parsed.letters || []);
      this.$store.commit("setPromptmarks", parsed.prompts || []);
      this.$store.commit("setBookmarks", parsed.fandoms || []);
    },
  },
};
</script>

<style lang="scss" scoped>
.bookmarks {
  table th {
    width: auto;

    &.fave {
      width: 20px;
    }
  }

  .prompts th.characters,
  .prompts th.fandom {
    width: 250px;
  }

  th.letters {
    width: 200px;
  }

  .move {
    padding: 2px 10px 2px 0px;
    margin-right: 3px;
    cursor: move !important;
  }

  th.fandom {
    width: 28%;
  }

  .letters {
    li {
      padding-left: 10px;
    }
  }

  .options,
  .letters,
  .fandoms {
    margin-top: 10px;
  }

  .counts {
    font-size: 13px;
    margin-left: 22px;
  }

  .remove {
    color: #e74c3c;
    &:hover {
      color: #e4a6a6;
    }
  }

  .show-load {
    cursor: pointer;
  }

  .load-wrapper {
    margin-bottom: 10px;
    display: flex;
    flex-wrap: wrap;
  }

  textarea {
    margin-left: 5px;
    vertical-align: top;
    width: 150px;
  }

  .load {
    padding: 7px;
    font-weight: bold;
  }
}

@media screen and (max-width: 767px) {
}
</style>
