<template>
  <section class="prompts split">
    <a href="#" @click.prevent="hide = !hide">
      {{ hide ? `Expand Fandom (${prompts.length})` : "Collapse Fandom" }}
    </a>

    <template v-if="!hide">
      <div class="prompt" v-for="(prompt, index) in prompts" :key="index">
        <div class="user-data">
          <button
            class="bookmark btn btn-link"
            @click="togglePromptmark(prompt)"
          >
            <span v-if="hasPromptmark(prompt)" class="fas fa-heart"></span>
            <span v-else class="far fa-heart"></span>
          </button>
          <strong>
            <a
              href="#"
              @click.prevent="
                $router.push({ path: `/user/${prompt.username}` })
              "
              >{{ prompt.username }}</a
            >
          </strong>
        </div>

        <div class="flex">
          <div class="requested-characters">
            <strong class="mobile">Requested Characters &nbsp;</strong>
            <ul v-if="prompt.characters" class="characters">
              <li v-for="c in prompt.characters" :key="c">{{ c }}</li>
            </ul>
            <span v-else>Any</span>
            <div class="divider"> </div>
            <span>{{ prompt.freeforms.join('') }}</span>
          </div>

          <div>
            <template v-if="prompt.prompt">
              <div
                v-if="
                  showPrompt[index] == true ||
                  !$store.state.options.useToggles ||
                  forceShow
                "
                v-html="prompt.prompt"
              ></div>
              <button
                v-if="$store.state.options.useToggles && !forceShow"
                class="btn btn-sm mb-2"
                @click="show(index)"
              >
                {{ !showPrompt[index] ? "Read prompt" : "Show less" }}
              </button>
            </template>

            <p v-else-if="!prompt.prompt">
              No optional details given.
              <span v-if="prompt.letter">
                However, they do have a
                <a :href="formatUrl(prompt.letter)" target="blank">letter</a>.
              </span>
            </p>

            <div v-if="prompt.letter">
              <a :href="formatUrl(prompt.letter)" target="blank" class="letter"
                >Letter</a
              > <span data-tooltip="If a request has HTML links in 
the optional details but no letter, 
the scraper interprets the last link
as a letter. Alas, this cannot be 
fixed due to the nature of AO3's
request page." class="tooltip c-hand"><sup><i aria-hidden="true" class="fa fa-exclamation-circle"></i></sup></span>
            </div>
          </div>
        </div>

        <hr class="break" />
      </div>
    </template>
  </section>
</template>

<script>
import utils from "./utils.js";
export default {
  name: "splitview",
  props: {
    prompts: {
      type: Array,
      default() {
        return [];
      },
    },
    forceShow: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hide: false,
      showPrompt: {},
    };
  },
  created() {
    if (!this.prompts || typeof this.prompts !== "object") {
      return;
    }
    this.prompts.forEach((element, i) => {
      this.showPrompt[i] = false;
    });
  },
  methods: {
    ...utils,
    show(index) {
      let newShow = this.showPrompt;
      newShow[index] = !newShow[index];
      this.showPrompt = {};
      this.showPrompt = newShow;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/variables.scss";

.prompt {
  margin: 0.8em 0;
  // border-bottom: 1px solid $outline-light;

  &:last-child {
    .break {
      display: none;
    }
  }
}

.bookmark {
  height: 1.3em;
}

.user-data {
  font-size: larger;

  .letter {
    padding: 0;
    margin: 0;
    vertical-align: baseline;
  }

  .bookmark {
    position: relative;
    bottom: 4px;
    margin-right: 0.4em;
  }
}

.flex {
  margin-top: 0.4em;
  display: flex;
}

ul {
  li {
    margin: 0;
    padding: 4px 0;
    border-bottom: 1px solid $outline-light;

    &:last-child {
      border: 0;
    }

    &:first-child {
      padding-top: 0;
    }
  }
}

.requested-characters {
  // margin-top: 1em;
  margin-right: 1em;
  flex: 0 0 25%;
}

.toggle {
  width: 150px;
  height: 35px;
  display: block;
}

.break {
  width: 100%;
  margin: 0.8em auto;
}

@media screen and (max-width: 767px) {
  .flex {
    display: inherit;
  }

  .requested-characters {
    display: block;
    margin-bottom: 0.5em;
  }
}
</style>

<style lang="scss">
.prompt-text {
  p:first-child {
    margin-top: 0;
  }
}

.prompts.split {
  .prompt-text {
    p:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
